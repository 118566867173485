<template>
  <div>
    <!-- Filters -->
    <news-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
      @filter="fetchNews"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @change="changePageNews({page:1 , itemsPerPage: $event})"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative pb-3"
        :items="news"
        :per-page="perPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Title -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.thumbnail"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-news-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-news-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </b-link>
            <!--            <small class="text-muted">{{ data.item.name }}</small>-->
          </b-media>
        </template>
        <!-- Column: Description -->
        <template #cell(description)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ shortDescription(data.item.description) }}</span>
          </div>
        </template>
        <!-- Column: published_at -->
        <template #cell(published_at)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.published_at }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'apps-news-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'apps-news-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="updateSort(data.item.id)">
              <feather-icon icon="ArrowUpIcon"/>
              <span class="align-middle ml-50">Send to top</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteNews(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of
              {{ pagination.itemsLength }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="pagination.itemsLength"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePageNews({ page: $event , itemsPerPage: perPage})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import NewsRepository from '@/abstraction/repository/newsRepository'
import NewsListFilters from './NewsListFilters.vue'
import useNewsList from './useNewsList'
import userStoreModule from '../user/userStoreModule'
// repository
const newsRepository = new NewsRepository()
export default {
    components: {
        NewsListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
    },
    data() {
        return {
            form: {
                description: null,
            },
        }
    },

    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })

        const roleOptions = [
            {
                label: 'Admin',
                value: 'admin',
            },
            {
                label: 'Author',
                value: 'author',
            },
            {
                label: 'Editor',
                value: 'editor',
            },
            {
                label: 'Maintainer',
                value: 'maintainer',
            },
            {
                label: 'Subscriber',
                value: 'subscriber',
            },
        ]

        const planOptions = [
            {
                label: 'Basic',
                value: 'basic',
            },
            {
                label: 'Company',
                value: 'company',
            },
            {
                label: 'Enterprise',
                value: 'enterprise',
            },
            {
                label: 'Team',
                value: 'team',
            },
        ]

        const statusOptions = [
            {
                label: 'Pending',
                value: 'pending',
            },
            {
                label: 'Active',
                value: 'active',
            },
            {
                label: 'Inactive',
                value: 'inactive',
            },
        ]

        const {
            fetchNews,
            tableColumns,
            perPage,
            pagination,
            currentPage,
            totalUsers,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            news,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useNewsList()

        onMounted(async () => {
           await fetchNews()
        })

        const changePageNews = paginate => {
            fetchNews(paginate)
        }

      const updateSort = async id => {
        await newsRepository.updateSort(id)
        await fetchNews({page: currentPage, perPage: perPage})
      }

      return {
            // Sidebar
            fetchNews,
            news,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            changePageNews,
            // Filter
            avatarText,
            updateSort,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        }
    },
    methods: {
        async deleteNews(newsId) {
            await newsRepository.delete(newsId)
            const index = this.news.findIndex(x => x.id === newsId)
            this.$delete(this.news, index)
        },
        shortDescription(value) {
            const words = value.split(' ')
            const wordsSliced = words.slice(0, 9)
            if (words.length > 10) {
                return `${wordsSliced.join(' ')}...`
            }
            return wordsSliced.join(' ')
        },
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
  color: $red;
}
</style>
